import model from './model';

export default model.createController(({ $w, $widget, flowAPI, appData }) => {
  return {
    pageReady: async () => {
      console.log('##', 'widgetLoaded 🎈🎈🎈');

      console.log('THIS IS POST: appData.postForTesting', appData);

      $widget.fireEvent('widgetLoaded', {});
    },
    exports: model.getExports(),
  };
});
