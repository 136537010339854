import { InitAppForPageFn } from '@wix/yoshi-flow-editor';

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServicesApis,
  flowAPI,
) => {
  return {
    postForTesting: 'THIS POST IS PASSED TO ALL COMPONENTS',
  };
};
